<template>
	<div id="landList">
		<page-work-order :is-shop="1"></page-work-order>
	</div>
</template>
<script>
	import pageWorkOrder from '@/components/layout/land/page-work-order.vue'
	export default{
		components:{
			pageWorkOrder
		},
	}
</script>

<style>
</style>
